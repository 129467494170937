@let post = article();
@let params = loadParams();

<a [routerLink]="'/' + post.full_slug">
    @if (post.content.image) {
        <img class="app-article-preview--image"
             [ngSrc]="post.content.image.filename"
             [height]="params?.height || 0"
             [width]="params?.width || 0"
             [alt]="post.content.image.alt"
             [title]="post.content.image.title"
        >
    }
    @if (post.content.title?.length) {
        <h3>
            {{post.content.title}}
        </h3>
    }
    @if (post.content.description) {
        <p>
            {{post.content.description}}
        </p>
    }
</a>
