@let breadcrumb = breadcrumbs();

@if (breadcrumb && breadcrumb.length > 0) {
    <nav>
        <ol class="flex gap-5">
            @for (crumb of breadcrumb; track crumb.path; let isLast =$last) {
                <li class="text-base flex justify-center items-center">
                    <a [routerLink]="crumb.path">{{ crumb.label }}</a>
                </li>
                @if(!isLast){
                    <span>></span>
                }
            }
        </ol>
    </nav>
}
