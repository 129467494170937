@let articles = blogCategory();
<app-layout [block]="layout()">
    @for (child of block().body; track child['_uid']) {
        <s1-cms-component [blok]="child"/>
    }
    @if (articles) {
        <app-articles-list [articles]="articles" />

        <app-pagination [(page)]="page"
                        (pageChange)="pageChange($event)"
                        [total]="articles?.total || 1"
                        [per_page]="articles?.perPage || 5" />
    }
</app-layout>
