import {
    ActivatedRouteSnapshot, Data,
    ResolveFn,
    RouterStateSnapshot
} from '@angular/router';
import { inject } from '@angular/core';
import { ISbError, ISbStory } from 'storyblok-js-client';
import { environment } from '../../../environments/environment';
import { StoryblokService } from '@seven1/angular-storyblok/api';
import { S1Layout } from '@seven1/model';

export interface LayoutData extends Data {
    layout: ISbStory | ISbError;
}

export const layoutResolver: ResolveFn<S1Layout | ISbError> = async (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    let slug: string = environment.cms.layoutStorySlug;
    if (slug?.startsWith('/')) {
        slug = slug.substring(1);
    }
    const cmsService = inject(StoryblokService);

    return new Promise<any>((resolve, reject) => {
        cmsService
            .getStory<S1Layout>(slug!)
            .then((story) => {
                if (!story) {
                    reject(new Error('Layout not found'));
                }
                const layoutConfig: S1Layout = {
                    headerImage: story.content.headerImage || [],
                    headerImageLink: story.content.headerImageLink || '',
                    headerPhoneNumber: story.content.headerPhoneNumber || '',
                    nav_children: story.content.nav_children || [],
                    phoneVariant: story.content.phoneVariant,
                    footerLogo: story.content.footerLogo || [],
                    footer_children1: story.content.footer_children1 || [],
                    footer_children2: story.content.footer_children2 || [],
                    footer_children3: story.content.footer_children3 || [],
                    footer_children4: story.content.footer_children4 || [],
                    footer_children5: story.content.footer_children5 || [],
                    footer_children6: story.content.footer_children6 || [],
                    footer_recruit: story.content.footer_recruit || [],
                    footerBackgroundColor: story.content.footerBackgroundColor || '',

                }
                resolve(layoutConfig);
            })
            .catch((e) => {
                console.error(
                    'Trying to get 404 story because we could not find ' + slug,
                    e
                );
            });
    });
};
