import { ChangeDetectionStrategy, Component, computed, inject, input, ViewEncapsulation } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { ISbStoryData } from 'storyblok-js-client';
import { IBlogArticleBlok } from '../../../../../blog';
import { ISbImageLoadParams } from '@seven1/model';
import { ImageService } from '@seven1/angular-storyblok/image';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-article-preview',
    imports: [NgOptimizedImage, RouterLink],
    templateUrl: './article.preview.html',
    styleUrl: './article.preview.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'app-article-preview',
    },
})
export class BlogArticlePreview {
    private _images = inject(ImageService);
    article = input.required<ISbStoryData<IBlogArticleBlok>>();

    loadParams = computed<ISbImageLoadParams | null>(() => {
        const block = this.article().content.image;
        if (block?.filename?.length) {
            return (this._images.extractUrlInfo(block.filename) || {}) as ISbImageLoadParams;
        }
        return null;
    });
}
