import { ChangeDetectionStrategy, Component, computed, inject, input, ViewEncapsulation } from '@angular/core';
import { CmsComponent } from '@seven1/angular-storyblok/components';
import { TailwindService } from '../../../../service';
import { IImportantInfoBlock} from './important-info.model';

@Component({
    selector: 'app-important-info',
    imports: [
        CmsComponent,
    ],
    templateUrl: './important-info.component.html',
    styleUrl: './important-info.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class]': 'classes()',
        'class': 'flex gap-y-5 flex-col rounded-xl'
    }
})
export class ImportantInfoComponent {
    private _tailwind = inject(TailwindService);
    block = input.required<IImportantInfoBlock>();

    classes = computed<string[]>(() => {
        return [
            ...this._tailwind.getDisplayClasses(this.block()),
            ...this._tailwind.getSpacingClasses(this.block()),
            ...this._tailwind.getBackgroundColorClasses(this.block()),
            ...this._tailwind.getBackgroundOpacityClasses(this.block())
        ]
    });
}
