import { ChangeDetectionStrategy, Component, computed, effect, inject, input, signal, ViewEncapsulation } from '@angular/core';
import { ITariffTableRowBlock } from './tariff-table-row.model';
import { ITariffTableColumnBlock } from '../column';
import { TariffTableRowColumnComponent } from './column';
import { ITooltipBlock, TooltipComponent } from '../../../tooltip';
import { NgOptimizedImage } from '@angular/common';
import { toSignal } from '@angular/core/rxjs-interop';
import { BreakpointsService } from '../../../../../../../media';

@Component({
    selector: 'div[app-tariff-table-row]',
    imports: [TariffTableRowColumnComponent, TooltipComponent, NgOptimizedImage],
    templateUrl: './tariff-table-row.component.html',
    styleUrl: './tariff-table-row.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'app-tariff-table--row',
        '[class.app-tariff-table--row-childless]': '!children()?.length',
        '[class.app-tariff-table--row-with-children]': 'children()?.length',
    },
})
export class TariffTableRowComponent {
    private breakpoints = inject(BreakpointsService);
    block = input.required<ITariffTableRowBlock>();
    columns = input<ITariffTableColumnBlock[]>();
    children = input<ITariffTableRowBlock[]>();
    showChildren = signal(false);
    minLg = toSignal(this.breakpoints.minLg$);
    columnsStyle = input<string>();
    titleColumnStartStyle = input<string>();

    tooltip = computed<ITooltipBlock | undefined>(() => {
        const b = this.block();
        if (b.info) {
            return { text: b.info };
        }
        return undefined;
    });

    constructor() {
        effect(() => {
            const minLg = this.minLg();
            if (minLg?.matches) {
                this.showChildren.set(true);
            } else {
                this.showChildren.set(false);
            }
        });
    }

    toggle() {
        if (!this.minLg()?.matches) {
            this.showChildren.set(!this.showChildren());
        }
    }
}
