import { UrlSegment } from '@angular/router';
import { environment } from '../../../environments/environment';

/** (url: UrlSegment[], group: UrlSegmentGroup, route: Route) =>  UrlMatchResult | null */
export const blogMatcher = (url: UrlSegment[]) => {
    const config = environment.cms;
    const segments = config.blogSlug.split('/').filter(path => path !== '');
    if (
        segments.length === url.length
        && url.every((seg, index) => seg.path === segments[index])
    ) {
        return { consumed: url };
    }
    return null;
}
