@let blogArticles = blog();

<app-layout [block]="layout()">
    @for (child of block().body; track child['_uid']) {
        <s1-cms-component [blok]="child" />
    }
    @if (blog()) {
        <app-articles-list [articles]="blog()!" />

        <app-pagination [(page)]="page"
                        (pageChange)="pageChange($event)"
                        [total]="blogArticles?.total || 1"
                        [per_page]="blogArticles?.perPage || 5" />
    }
</app-layout>
