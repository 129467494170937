import { ActivatedRouteSnapshot, Data, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import { ISbStories } from 'storyblok-js-client';
import { StoryblokService } from '@seven1/angular-storyblok/api';
import { IBlogArticleBlok } from '../blog';

export interface BlogArticleData extends Data {
    blogArticle: ISbStories<IBlogArticleBlok> | null;
}

export const blogArticleResolver: ResolveFn<ISbStories<IBlogArticleBlok> | null> = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const service = inject(StoryblokService);

    return new Promise<ISbStories<IBlogArticleBlok> | null>((resolve, reject) => {
        service.getStories<IBlogArticleBlok>({
            content_type: 'BlogArticlePage',
            sort_by: 'created_at:desc',
            excluding_fields: 'body',
            per_page: 5,
            version: service.config.version,
        }).then((res) => {
            resolve(res);
        }).catch(err => {
            resolve(null);
        })
    });
};
