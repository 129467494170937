import { ChangeDetectionStrategy, Component, effect, inject, input, model, signal, ViewEncapsulation } from '@angular/core';
import { IBlogArticleBlok, IBlogCategoryBlok } from '../../../../blog';
import { S1Layout } from '@seven1/model';
import { ISbStories } from 'storyblok-js-client';
import { SeoService } from '@seven1/angular/seo';
import { LayoutWrapper } from '../../layout';
import { CmsComponent } from '@seven1/angular-storyblok/components';
import { BlogArticlesList } from '../article';
import { PaginationComponent } from '../../../pagination';
import { StoryblokService } from '@seven1/angular-storyblok/api';

@Component({
    selector: 'app-blog-category-wrapper',
    imports: [LayoutWrapper, CmsComponent, BlogArticlesList, PaginationComponent],
    templateUrl: './category.wrapper.html',
    styleUrl: './category.wrapper.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlogCategoryWrapper {
    private _seo = inject(SeoService);
    private _story = inject(StoryblokService);
    block = input.required<IBlogCategoryBlok>();
    layout = input.required<S1Layout>();
    blogCategory = model<ISbStories<IBlogArticleBlok> | null>(null);
    page = signal(1);

    constructor() {
        effect(() => {
            const blok = this.block();
            this._seo.addPageMeta(blok);
        });
    }

    async pageChange(page: number) {
        const res = await this._story.getStories<IBlogArticleBlok>({
            content_type: 'BlogArticlePage',
            sort_by: 'created_at:desc',
            excluding_fields: 'body',
            per_page: 5,
            page: page,
            version: this._story.config.version
        });
        this.blogCategory.set(res);
    }
}
