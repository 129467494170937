import { ChangeDetectionStrategy, Component, computed, inject, input, ViewEncapsulation } from '@angular/core';

import { TailwindService } from '../../../../service';
import { ISectionBlock } from './section.model';
import { CmsComponent } from '@seven1/angular-storyblok/components';
import { KameleoonService } from '@seven1/angular/kameleoon';
import { ActivatedRoute } from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';
import { environment } from '../../../../../../environments/environment';

@Component({
    selector: 'section[app-section]',
    imports: [CmsComponent],
    templateUrl: './section.component.html',
    styleUrl: './section.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'app-section',
        '[class]': 'classes()',
        '[class.!hidden]': '!visible()',
        '[attr.id]': 'block().componentId',
    }
})
export class SectionComponent {
    environment = environment;
    private _route = inject(ActivatedRoute);
    private _kameleoon = inject(KameleoonService);
    private _queryParams = toSignal(this._route.queryParamMap);
    private _tailwind = inject(TailwindService);

    block = input.required<ISectionBlock>();

    classes = computed(() => {
        const block = this.block();
        const classes = [
            ...this._tailwind.getSpacingClasses(block),
            ...this._tailwind.getBackgroundColorClasses(block),
            ...this._tailwind.getDisplayClasses(block)
        ];
        if (block.limitedWidth) {
            classes.push('app-section--limited-width')
        }
        return classes;
    });

    visible = computed(() => {
        return this._kameleoon.visible(this._queryParams(), this.block());
    });
}
