@let blok = block();

@if (blok.children) {
    @if (blok.children.length > 0) {
        @for (child of blok.children; track child._uid) {
            <s1-cms-component [blok]="child"/>
        }
    } @else {
        QuoteBox
    }
}

<div [ngClass]="[triangleOpacityClasses(), trianglePositionClasses()]"
     class="absolute bottom-0 w-0 h-0 border-l-[16px] border-l-transparent border-t-[16px] transform border-r-[16px] border-r-transparent"></div>


