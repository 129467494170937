import { ActivatedRouteSnapshot, Data, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import { ISbError, ISbStories, ISbStory } from 'storyblok-js-client';
import { StoryblokService } from '@seven1/angular-storyblok/api';
import { IBlogArticleBlok } from '../blog';

export interface BlogCategoryData extends Data {
    blogCategory: ISbStory | ISbError;
}

export const blogCategoryResolver: ResolveFn<ISbStories<IBlogArticleBlok> | null> = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const service = inject(StoryblokService);

    return new Promise<ISbStories<IBlogArticleBlok> | null>((resolve, reject) => {
        const starts_with = route.url.map(url => url.path).join('/');
        console.assert({starts_with})
        service.getStories<IBlogArticleBlok>({
            content_type: 'BlogArticlePage',
            sort_by: 'created_at:desc',
            starts_with: starts_with,
            excluding_fields: 'body',
            per_page: 5,
            version: service.config.version,
        }).then((res) => {
            resolve(res);
        }).catch(err => {
            resolve(null);
        })
    });
};
