import { IEnvironment } from './environment.interface';
import { STORYBLOK_COMPONENTS } from './components';
import { SB_RICH_TEXT_OPTIONS } from '@seven1/angular-storyblok/api';
import { STORYBLOK_IFRAMES } from './iframe';

export const environment: IEnvironment = {
    production: true,
    preview:true,
    baseUrl: 'https://www.petprotect.de',
    cms: {
        accessToken: '9RwWhKb3s5D67eniqMOjeQtt',
        layoutStorySlug: '/layout',
        blogSlug: '/blog',
        blogCategoriesSlug: '/blog/*',
        blogArticlesSlug: '/blog/**/*',
        version: 'draft',
        components: STORYBLOK_COMPONENTS,
        cache: {
            clear: 'auto',
            type: 'memory',
        },
        richText: SB_RICH_TEXT_OPTIONS,
    },
    iframes: STORYBLOK_IFRAMES,
    google_tag_manager: {
        url: 'https://www.googletagmanager.com/',
        id: 'GTM-TV7SLBL',
    },
    usercentrics: {
        id: 'eGSsZVigU',
        url: 'https://s.p7s1.io/cmp/cmp-petprotect-web.js',
        targetUrl: 'https://versichern.petprotect.de',
        excludedRoutes: ['/impressum', '/datenschutz']
    },
    kameleoon: {
        siteCode: 'vntkkxt0si',
    },
};
