@let blok = block();
@if (environment.preview) {
    @for (child of blok.children; track child._uid) {
        <s1-cms-component [blok]="child"/>
    }
} @else {
    @defer (hydrate on interaction) {
        @for (child of blok.children; track child._uid) {
            <s1-cms-component [blok]="child"/>
        }
    }
}
