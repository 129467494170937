import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { ISbStories } from 'storyblok-js-client';
import { IBlogArticleBlok } from '../../../../../blog';
import { BlogArticlePreview } from '../preview';

@Component({
    selector: 'app-articles-list',
    imports: [BlogArticlePreview, BlogArticlePreview],
    templateUrl: './articles.list.html',
    styleUrl: './articles.list.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlogArticlesList {
    articles = input.required<ISbStories<IBlogArticleBlok>>();
}
