import { ActivatedRouteSnapshot, Data, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import { ISbError, ISbStory, ISbStoryData } from 'storyblok-js-client';
import { StoryblokService } from '@seven1/angular-storyblok/api';
import { STORY_ROUTE_PARAM_KEY } from '../../app.routes';

const DEFAULT_SLUG = 'home';

export interface StoryData extends Data {
    story: ISbStory | ISbError;
}

export const storyResolver: ResolveFn<ISbStoryData | ISbError> = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    let slug: string | undefined | null = undefined;
    if (route.paramMap.has(STORY_ROUTE_PARAM_KEY)) {
        slug = route.paramMap.get(STORY_ROUTE_PARAM_KEY);
    } else {
        slug = route.pathFromRoot.map((v) => v.url.map((segment) => segment.toString()).join('/')).join('/');
    }
    if (slug?.startsWith('/')) {
        slug = slug.substring(1);
    }

    if (!slug?.length) {
        slug = DEFAULT_SLUG;
    }

    const service = inject(StoryblokService);

    return new Promise<ISbStoryData | ISbError>((resolve, reject) => {
        service
            .getStory(slug!)
            .then((story) => {
                if (!story) {
                    reject(new Error('Story not found'));
                }
                resolve(story);
            })
            .catch((e) => {
                console.error('Trying to get 404 story because we could not find ' + slug, e);
                //
                // // TODO check and fix + SSR not working because of this part (?)
                // let responseInit = inject(
                //   new InjectionToken<ResponseInit>('RESPONSE_INIT_OPTIONS')
                // );
                // responseInit.status = 404;

                service
                    .getStory('error_404')
                    .then((story) => {
                        resolve(story);
                    })
                    .catch(() => {
                        reject(e);
                    });
            });
    });
};
