import { ChangeDetectionStrategy, Component, computed, inject, input, ViewEncapsulation } from '@angular/core';
import { TailwindService } from '../../../../service';
import { IQuoteBoxBlock } from './quotebox.model';
import { NgClass } from '@angular/common';
import { CmsComponent } from '@seven1/angular-storyblok/components';

@Component({
    selector: 'app-quotebox',
    templateUrl: './quotebox.component.html',
    styleUrl: './quotebox.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NgClass,
        CmsComponent
    ],
    host: {
        '[class]': 'hostClasses()',
        class: 'block relative p-4 rounded-lg',
    }
})
export class QuoteboxComponent {
    private _tailwind = inject(TailwindService);
    block = input.required<IQuoteBoxBlock>();

    hostClasses = computed<string[]>(() => {
        return [
            ...this._tailwind.getDisplayClasses(this.block()),
            ...this._tailwind.getBackgroundColorClasses(this.block()),
            ...this._tailwind.getSpacingClasses(this.block()),
            ...this._tailwind.getBackgroundOpacityClasses(this.block())
        ]
    });


    triangleOpacityClasses = computed<string>(() => {
        let opacity;
        if (this.block().backgroundOpacity) {
            opacity = this._tailwind.getOpacityClasses({opacity: this.block().backgroundOpacity}).toString()
        }
        let color;
        switch (this.block().backgroundColor) {
            case 'primary':
                color = 'border-t-primary';
                break;
            case 'secondary':
                color = 'border-t-secondary';
                break;
            case 'accent-light':
                color = 'border-t-accent-light';
                break;
            case 'accent-dark':
                color = 'border-t-accent-dark';
                break;
            case 'grey-dark':
                color = 'border-t-grey-dark';
                break;
            case 'grey-light':
                color = 'border-t-grey-light';
                break;
            case 'white':
                color = 'border-t-white';
                break;
            default:
                color = 'border-t-primary';
                break;
        }
        if (opacity) {
            return opacity + ' ' + color;
        }
        return color;
    });

    trianglePositionClasses = computed<string>(() => {
        return this.block().orientation === 'left' ? 'left-0 translate-x-4 translate-y-4' : 'right-0 -translate-x-4 translate-y-4';
    });
}

