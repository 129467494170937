import { UrlSegment } from '@angular/router';
import { environment } from '../../../environments/environment';

/** (url: UrlSegment[], group: UrlSegmentGroup, route: Route) =>  UrlMatchResult | null */
export const blogArticleMatcher = (url: UrlSegment[]) => {
    const config = environment.cms;
    const segments = config.blogArticlesSlug.split('/').filter(path => path !== '');
    if (
        segments.length <= url.length
        && segments.every((seg, index) => seg === url[index].path || seg === '**' || seg === '*')
    ) {
        return { consumed: url };
    }
    return null;
}
