import { Route } from '@angular/router';
import { layoutResolver, storyResolver, blogResolver, blogArticleResolver, blogCategoryResolver } from './cms/resolver';
import { routeChangeActivateGuard, routeChangeDeactivateGuard } from '@seven1/angular/gtm';
import { navigationDeactivateGuard } from './routing/route-change-activate.guard';
import redirectedRoutes from '../../public/data/redirectedRoutes.json'
import { blogMatcher, blogArticleMatcher, blogCategoryMatcher } from './cms/matcher';

export const STORY_ROUTE_PARAM_KEY = 'story-slug';

export const appRoutes: Route[] = [
    ...(redirectedRoutes as Route[]),
    {
        matcher: blogMatcher,
        loadComponent: () => import('./cms/blog').then(c => c.BlogPage),
        resolve: {
            story: storyResolver,
            blog: blogResolver,
            layout: layoutResolver,
        },
    },
    {
        matcher: blogArticleMatcher,
        loadComponent: () => import('./cms/blog').then(c => c.BlogArticlePage),
        resolve: {
            story: storyResolver,
            blogArticle: blogArticleResolver,
            layout: layoutResolver,
        },
    },
    {
        matcher: blogCategoryMatcher,
        loadComponent: () => import('./cms/blog').then(c => c.BlogCategoryPage),
        resolve: {
            story: storyResolver,
            blogCategory: blogCategoryResolver,
            layout: layoutResolver,
        },
    },
    {
        path: '**',
        loadComponent: () => import('./cms/page').then((cmp) => cmp.CmsPage),
        resolve: {
            story: storyResolver,
            layout: layoutResolver,
        },
        canActivate: [routeChangeActivateGuard],
        canDeactivate: [routeChangeDeactivateGuard, navigationDeactivateGuard],
    },
];
