[
  {
    "path": "cookie-hinweise",
    "redirectTo": "/datenschutz",
    "pathMatch": "full",
    "statusCode": "301"
  },
  {
    "path": "videosprechstunde-2",
    "redirectTo": "/videosprechstunde",
    "pathMatch": "full",
    "statusCode": "301"
  }
]